<template>
  <div>
    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit, invalid }"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(submitForm)"
      >
        <MainSection
          :categories="allowedCategories"
          :countries="countries"
        />
        
        <Destination />

        <DestinationSchedulingCapacity />

        <AlertProgrammaticTarget
          class="mx-2"
          :routing-type="buyerCampaign.routing.type"
        />

        <TrafficSources :traffic-sources="allowedTrafficSources" />

        <Audience />

        <!-- Integrations is for configuring Pixels, but maybe not required for MVP: Adam -->
        <!-- <Integrations /> -->

        <Budget
          :invalid="invalid"
          :minimum-initial-deposit-satisfied="isMinimumInitialDepositRequirementSatisfied"
          :loading="isLoading"
          @submit="submitForm"
        />
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import MainSection from '../campaign/MainSection.vue'
import Destination from '../campaign/Destination.vue'
import DestinationSchedulingCapacity from '../campaign/DestinationSchedulingCapacity.vue'
import TrafficSources from '../campaign/TrafficSources.vue'
import Audience from './Audience.vue'
import Budget from './Budget.vue'
import AlertProgrammaticTarget from './AlertProgrammaticTarget.vue'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import { ACCOUNT_REQUIREMENTS, dashboardNotificationForAccountRequirementsMessages } from '@/store/modules/auth.store'

export default {
  name: 'BuyerCreateCampaign',

  title: 'Create campaign',

  components: {
    MainSection,
    Destination,
    DestinationSchedulingCapacity,
    TrafficSources,
    Audience,
    Budget,
    AlertProgrammaticTarget,
  },

  props: {
    id: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapState('common-entities', ['countries']),

    ...mapGetters('account', ['allowedTrafficSources', 'allowedCategories']),

    ...mapState('campaign', ['buyerCampaign']),

    ...mapGetters('auth', ['isMinimumInitialDepositRequirementSatisfied']),

  },

  watch: {
    isMinimumInitialDepositRequirementSatisfied: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.showDashboardNotification(dashboardNotificationForAccountRequirementsMessages[ACCOUNT_REQUIREMENTS.MinimumInitialDeposit])
        } else {
          this.hideDashboardNotification()
        }
      }
    }
  },

  async created() {
    this.setDefaultBuyerCampaign()
    await this.getCountries()
  },

  methods: {
    ...mapActions([
      'showDashboardNotification',
      'hideDashboardNotification'
    ]),

    // Also, the list of categories and countries returned should
    // be specific to a buyer. The API endpoint should use the
    // id of the logged in user to fetch specific categories and countries
    // TODO: Set allowed countries for an account?
    ...mapActions('common-entities', ['getCountries']),

    ...mapActions('campaign', ['setDefaultBuyerCampaign', 'createBuyerCampaign', 'fetchBuyerCampaign']),

    async submitForm() {
      try {
        this.isLoading = true

        await this.createBuyerCampaign(this.buyerCampaign)

        // TODO: Show a detailed message about the approval process
        // and redirect to campaigns list page
        showSuccessMessage('Campaign created successfully')
        this.$router.push({ name: 'ListBuyerCampaigns' })
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>

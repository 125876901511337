<template>
  <div class="panel d-flex flex-column">
    <div
      v-if="!hideHeader"
      class="panel__header d-flex align-items-center"
    >
      <font-awesome-icon
        v-if="size === 'big'"
        class="mr-2 fa-lg"
        :icon="icon"
      />

      <h6 class="mb-0">
        <slot name="header">
          Targets
        </slot>
      </h6>
    </div>

    <div class="panel__content p-3">
      <div
        v-if="loading || showLoadingError"
        class="d-flex align-items-center justify-content-center p-5"
      >
        <Loader
          v-if="loading && !showLoadingError"
          :loader-text="loaderText"
        />

        <div
          v-if="showLoadingError"
          class="text-danger"
        >
          <font-awesome-icon
            :icon="['fas', 'exclamation-triangle']"
            style="margin-bottom: 2px;"
          />
          <em
            class="table__status-text"
          >{{ loadingErrorText }}</em>
        </div>
      </div>

      <slot
        v-if="!loading && !showLoadingError"
        name="content"
      >
        Content
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Panel',

  props: {
    size: {
      type: String,
      default: 'big'
    },
    hideHeader: {
      type: Boolean,
      default: false
    },

    icon: {
      type: Array,
      default() {
        return ['fas', 'dot-circle']
      }
    },

    loading: {
      type: Boolean,
      default: false
    },

    loaderText: {
      type: String,
      default: 'Loading'
    },

    showLoadingError: {
      type: Boolean,
      default: false
    },

    loadingErrorText: {
      type: String,
      default: 'Error'
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  background: var(--component-panel-bg);
  margin: 1.5rem;
  margin-bottom: 30px;
}

.panel__header {
  background: var(--component-panel__header-bg);
  padding: 15px 20px;
  color: var(--component-panel__title-font-color);
}
</style>

<style lang="scss">
.panel-holder {
  background: var(--component-panel-holder-bg);
  margin: 1.5rem;
  padding: 0.5rem;
}
</style>
import CreativesAPI from '@/api/creative.api'

export const creativeFileTypes = {
  Image: 'Image',
  Video: 'Video',
  Audio: 'Audio',
  Pdf: 'Pdf',  
  Unknown: 'Unknown'
}

export const MEDICARE_FLAG = 'MEDICARE'

const state = {  
  creativeFiles: [],
  creativeApprovalDocs: [],
  creatives: [],
  selectedCreative: {},
  creativeReviews: [],
  selectedCreativeReview: {}

}

const getters = {   
  getSelectedCreativeUrls(state) {
    return state.selectedCreative?.files?.map(f => f) ?? []
  },
  getSelectedCreativeReviewUrls(state) {
    return state.selectedCreativeReview?.creative?.files?.map(f => f) ?? []
  },

  suggestedCreativesForReviewByBuyer(state) {
    return state.creativeReviews.filter(creative => creative.isRecommendedForReview)
  }
}

const actions = {
  async uploadFile({ commit, rootGetters }, {request, progressCallback}) {
    const accountId = rootGetters['auth/accountDetails']?.id

    if (!request?.file) {
      return
    }    

    var fileId = null    
    
    if (request.file.size > 209715200) {
      // do not allow upload of files larger than 200 MB
      throw new Error('File too large, must be smaller than 200 MB')
    }
    else if (request.file.size < 10485760) 
    {
      // simple upload of a small file less than 10 MB      
      fileId = await uploadSimple({
        accountId: accountId, 
        file: request.file, 
        index: request.index, 
        progressCallback: progressCallback
      })
    }
    else {
      // do multipart upload of a large file
      fileId = await uploadMultipart({
        accountId: accountId, 
        file: request.file, 
        index: request.index, 
        progressCallback: progressCallback
      })
    }

    if (request.isApprovalDoc) {
      commit('ADD_APPROVAL_DOC', fileId)
    }
    else {
      commit('ADD_CREATIVE_FILE', fileId)
    }

    return fileId
  },  

  async createCreative({ rootGetters }, request) {
    const accountId = rootGetters['auth/accountDetails']?.id
    const response = await CreativesAPI.create({
      accountId: accountId, 
      request: request
    })
    return response
  },
  
  async getCreatives({ commit, rootGetters }) {
    const accountId = rootGetters['auth/accountDetails']?.id    
    const response = await CreativesAPI.getSellerCreatives({
      accountId: accountId
    })
    commit('SET_CREATIVES', response?.data || [])
    return response
  },  

  async getCreative({ commit, rootGetters }, creativeId) {
    const accountId = rootGetters['auth/accountDetails']?.id    
    const response = await CreativesAPI.getSellerCreative({
      accountId: accountId,
      creativeId: creativeId
    })
    commit('SET_SELECTED_CREATIVE', response?.data || [])
    return response
  },

  async getCreativeReview({ commit, rootGetters }, id) {
    const accountId = rootGetters['auth/accountDetails']?.id    
    const response = await CreativesAPI.getCreativeReview({
      accountId: accountId,
      id: id
    })
    commit('SET_SELECTED_CREATIVE_REVIEW', response?.data || [])
    return response
  }, 
  
  async getCreativeReviews({ commit, rootGetters }) {
    const accountId = rootGetters['auth/accountDetails']?.id    
    const response = await CreativesAPI.getCreativeReviews({
      accountId: accountId
    })
    commit('SET_CREATIVE_REVIEWS', response?.data || [])
    return response
  }, 

  async approveCreativeReview({ dispatch, rootGetters }, id) {
    const accountId = rootGetters['auth/accountDetails']?.id
    const response = await CreativesAPI.reviewBuyerCreative({
      accountId: accountId,
      request: {
        creativeReviewId: id, 
        isApproved: true
      }
    })
    
    await dispatch('getCreativeReviews')
    
    return response
  },

  async rejectCreativeReview({ dispatch, rootGetters }, {id, reason}) {
    const accountId = rootGetters['auth/accountDetails']?.id
    const response = await CreativesAPI.reviewBuyerCreative({
      accountId: accountId,
      request: {
        creativeReviewId: id, 
        isApproved: false,
        reason: reason
      }
    })
    
    await dispatch('getCreativeReviews')
   
    return response
  }, 

  async enableCreative({ dispatch, rootGetters }, id) {
    const accountId = rootGetters['auth/accountDetails']?.id
    const response = await CreativesAPI.enable({
      accountId: accountId,
      creativeId: id
    })
    
    await dispatch('getCreatives')

    return response
  },

  async disableCreative({ dispatch, rootGetters }, id) {
    const accountId = rootGetters['auth/accountDetails']?.id
    const response = await CreativesAPI.disable({
      accountId: accountId,
      creativeId: id
    })

    await dispatch('getCreatives')
    
    return response
  },
}

const mutations = {
  ADD_CREATIVE_FILE(state, data) {
    if (data) {
      state.creativeFiles.push(data)
    }
  },
  RESET_CREATIVE_FILES(state) {
    state.creativeFiles = []
  },
  REMOVE_CREATIVE_FILE(state, fileId) {
    const index = state.creativeFiles.indexOf(fileId)
    if (index >= 0) {
      state.creativeFiles.splice(index, 1)
    }
  },
  ADD_APPROVAL_DOC(state, data) {
    if (data) {
      state.creativeApprovalDocs.push(data)
    }
  },
  RESET_APPROVAL_DOCS(state) {
    state.creativeApprovalDocs = []
  },
  REMOVE_APPROVAL_DOC(state, fileId) {
    const index = state.creativeApprovalDocs.indexOf(fileId)
    state.creativeApprovalDocs.splice(index, 1)
  },
  SET_CREATIVES(state, data) {
    state.creatives = data    
  },
  SET_SELECTED_CREATIVE(state, data) {
    state.selectedCreative = data    
  },
  SET_SELECTED_CREATIVE_REVIEW(state, data) {
    state.selectedCreativeReview = data    
  },
  SET_CREATIVE_REVIEWS(state, data) {
    state.creativeReviews = data    
  }  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

function getFileType(file) {
  if (!file || !file.type){
    return creativeFileTypes.Unknown
  }
  if (file.type.includes('video')) {
    return creativeFileTypes.Video
  }
  if (file.type.includes('image')) {
    return creativeFileTypes.Image
  }
  if (file.type.includes('application/pdf')) {
    return creativeFileTypes.Pdf
  }
  if (file.type.includes('audio')) {
    return creativeFileTypes.Audio
  }
  return creativeFileTypes.Unknown
}

async function uploadSimple({accountId, file, index, progressCallback}) {
  const uploadResponse = await CreativesAPI.uploadSimple({
    accountId: accountId,
    request: {
      file: file, 
      index: index, 
      fileType: getFileType(file),
      fileName: file.name, 
    }, 
    progressCallback: progressCallback
  })

  return uploadResponse.data
}

async function uploadMultipart({accountId, file, index, progressCallback}) {
  const responseInitiate = await CreativesAPI.initiateUpload({
    accountId: accountId, 
    request: { 
      fileName: file.name, 
      fileType: getFileType(file)
    }
  })

  const fileKey = responseInitiate.data.fileKey
  const uploadId = responseInitiate.data.uploadId
  
  // chunk size 6MB, last chunk can be smaller
  const chunkSize = 1048576 * 6 
  const chunksQuantity = Math.ceil(file.size / chunkSize)
  const chunksQueue = new Array(chunksQuantity).fill().map((_, index) => index+1).reverse()
  const progressStep = Math.round(100 / chunksQueue.length)  
  var partETags = []

  while (chunksQueue.length > 0) {
    const chunkNumber = chunksQueue.pop()
    const sentSize = (chunkNumber - 1) * chunkSize
    const chunk = file.slice(sentSize, sentSize + chunkSize)        

    const responseUploadChunk = await CreativesAPI.uploadChunk({
      accountId: accountId, 
      request: {
        fileKey: fileKey,
        uploadId: uploadId,
        fileChunk: chunk,
        chunkNumber: chunkNumber,
        index: index
      },
      progressCallback: progressCallback,
      progressStart: progressStep * (chunkNumber - 1),
      progressEnd: progressStep * chunkNumber
    })

    partETags.push(responseUploadChunk.data)
  }      

  await CreativesAPI.completeUpload({
    accountId: accountId, 
    request: {
      fileKey: fileKey,
      uploadId: uploadId,
      eTags: partETags
    }
  })

  return responseInitiate.data?.fileId  
}
import httpClient from './httpClient'

const END_POINT = '/creatives'

const CreativesAPI = {
  async uploadSimple({accountId, request, progressCallback}) {
    const url = `${END_POINT}/seller/${accountId}/upload-simple`
    const formData = new FormData()
    formData.append('File', request.file)
    formData.append('FileType', request.fileType)
    formData.append('FileName', request.fileName)
    return await httpClient.put(url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: e => {
          let progress = (e.loaded / e.total) * 100
          progressCallback({progress: progress, index: request.index})
        },
      })    
  },

  async create({ accountId, request }) {
    const url = `${END_POINT}/seller/${accountId}`    
    return await httpClient.post(url, request)
  },

  async getSellerCreatives({ accountId }) {
    const url = `${END_POINT}/seller/${accountId}`    
    return await httpClient.get(url)
  },

  async getSellerCreative(payload) {
    const url = `${END_POINT}/seller/${payload.accountId}/creative/${payload.creativeId}`    
    return await httpClient.get(url)
  },

  async getCreativeReview(payload) {
    const url = `${END_POINT}/buyer/${payload.accountId}/review/${payload.id}`    
    return await httpClient.get(url)
  },

  async getCreativeReviews({ accountId }) {
    const url = `${END_POINT}/buyer/${accountId}`    
    return await httpClient.get(url)
  },

  async reviewBuyerCreative( {accountId, request} ) {
    const url = `${END_POINT}/buyer/${accountId}/review`    
    return await httpClient.post(url, request)
  },

  async enable(payload) {
    const url = `${END_POINT}/seller/${payload.accountId}/creative/${payload.creativeId}/enable` 
    return await httpClient.patch(url)
  },

  async disable(payload) {
    const url = `${END_POINT}/seller/${payload.accountId}/creative/${payload.creativeId}/disable`    
    return await httpClient.patch(url)
  },

  async initiateUpload({accountId, request}) {
    const url = `${END_POINT}/seller/${accountId}/upload-initiate`    
    return await httpClient.put(url, request)    
  },

  async uploadChunk({accountId, request, progressCallback, progressStart, progressEnd}) {
    const url = `${END_POINT}/seller/${accountId}/upload-chunk`
    const formData = new FormData()
    formData.append('FileKey', request.fileKey)
    formData.append('FileChunk', request.fileChunk)
    formData.append('UploadId', request.uploadId)
    formData.append('ChunkNumber', request.chunkNumber)

    return await httpClient.put(url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: e => {
          let progress = progressStart + (e.loaded / e.total) * (progressEnd - progressStart)
          progressCallback({
            progress: progress, 
            index: request.index
          })
        }        
      })    
  },

  async completeUpload({accountId, request}) {
    const url = `${END_POINT}/seller/${accountId}/upload-complete`    
    return await httpClient.post(url, request)    
  },
}

export default CreativesAPI

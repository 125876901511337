<template>
  <div class="d-flex flex-column mr-3">
    <VueLightbox
      v-if="lightboxVisible"      
      :show-gallery="true"
      :index="lightboxIndex"
      :items="getSelectedCreativeReviewUrls"      
      :toolbar="['counter', 'zoom','fullscreen','gallery','rotate','close']"
      :click-outside-hide="true"
      :z-index="1099"
      @close="lightboxVisible = false"
    >
      <template        
        v-slot:loading
      >
        <div>
          <h5 class="pt-5 text-center">
            Loading files...
          </h5>   
        </div>
      </template>          
    </VueLightbox>

    <b-toast
      v-if="selectedCreativeReview && selectedCreativeReview.status === 'Pending'"
      id="review-creative-popup"      
      v-model="lightboxVisible"
      title="Review creative" 
      no-auto-hide
      no-close-button
      toaster="b-toaster-bottom-right"
      solid      
    >
      <div>       
        <b-button
          variant="success"
          size="md"
          class="mr-3"
          @click="showConfirmApproveCreative"
        >         
          Approve
        </b-button>
        <b-button 
          variant="danger"
          size="md"
          @click="showConfirmRejectCreative"
        >
          Reject
        </b-button>         
      </div>
    </b-toast>
    
    <b-modal
      id="review-modal"
      ref="review-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Review Creative"
      ok-title="Approve"
      cancel-title="Reject"
      button-size="sm"
      @ok="approveCreative"
      @cancel="rejectCreative"
    >
      <p
        class="mb-2"
      >
        There are no media files uploaded. Do you want to <strong>approve</strong> or <strong>reject</strong> the creative?
      </p>

      <template #modal-footer>
        <BaseButtonLoader
          :loading="isLoadingApproved"
          :disabled="isLoadingRejected"
          type="button"
          :custom-button="false"
          size="sm"
          variant="primary"
          @click="approveCreative"
        >
          Approve
        </BaseButtonLoader>

        <BaseButtonLoader
          type="button"
          variant="danger"
          :loading="isLoadingRejected"
          :custom-button="false"
          size="sm"
          class="ml-2"
          :disabled="isLoadingApproved"
          @click="rejectCreative"
        >
          Reject
        </BaseButtonLoader>
      </template>
    </b-modal>
  
    <b-table
      :fields="fields"          
      :items="filteredReviews"
      responsive
      :per-page="pageSize"
      :current-page="currentPage"
      thead-class="text-nowrap"
      tbody-tr-class="text-nowrap"
      primary-key="id"
      show-empty
      :filter="filterByCreativeId"
      :filter-function="filterByCreativeIdField"
    >
      <template #empty>
        <h6><i /></h6>
      </template>

      <template
        v-if="!simpleView"
        v-slot:top-row
      >
        <td
          v-for="(field, $idx) in fields.filter(f => f.key !== 'actions')" 
          :key="$idx"
          :class="field.tdClass"
        >
          <input
            v-if="!dropdownFilterOptions[field.key]"              
            v-model="filters[field.key]"
            :placeholder="field.label"
            class="form-control custom-form-input--xs"
            :class="field.tdClass"
          >

          <b-form-select
            v-else
            v-model="filters[field.key]"
            :options="dropdownFilterOptions[field.key].filterOptions"
            class="form-control custom-form-input--xs"
            :placeholder="dropdownFilterOptions[field.key].placeholder"
            :class="dropdownFilterOptions[field.key].customClass"
          />
        </td>
      </template>

      <template #cell(status)="row">
        <b-badge
          v-if="row.item.status"
          tag="div"
          class="fixed-width-100 py-1"
          :variant="getStatusMap(row.item.status).variant"
        >
          {{ getStatusMap(row.item.status).text }}
        </b-badge>
      </template>

      <template #cell(name)="row">
        {{ row.item.name }}
      </template>

      <template #cell(cmsId)="row">
        {{ row.item.cmsId }}
      </template>
  
      <template #cell(landingPageUrl)="row">
        {{ row.item.landingPageUrl }}
      </template>
  
      <template #cell(categoryName)="row">
        {{ row.item.categoryName }}
      </template>
  
      <template #cell(trafficSourceName)="row">
        {{ row.item.trafficSourceName }}
      </template>
  
      <template #cell(country)="row">
        {{ row.item.country }}
      </template>
  
      <template #cell(language)="row">
        {{ row.item.language }}
      </template>
  
      <template #cell(actions)="row">
        <ButtonLoader
          v-if="row.item && row.item.status === 'Pending'"
          size="sm"
          variant="warning"            
          title="Review"
          :custom-button="false"
          :loading="Boolean(loadingFiles[row.item.id])"
          show-text-with-loader
          @click="loadCreativeReview(row.item)"
        >
          Review
        </ButtonLoader>

        <ButtonLoader
          v-if="row.item && row.item.status !== 'Pending' && row.item.creative.files && row.item.creative.files.length > 0"
          size="sm"        
          variant="primary"
          title="Show Media Files"
          :custom-button="false"
          :loading="Boolean(loadingFiles[row.item.id])"
          show-text-with-loader
          @click="loadCreativeReview(row.item)"
        >
          View
        </ButtonLoader>
      </template>
    </b-table>

    <b-modal
      id="confirm-approve-modal"
      ref="confirm-approve-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm Creative Approval"
      ok-title="Approve"
      button-size="sm"
      @ok="approveCreative"
    >
      <p
        v-if="selectedCreativeReview && selectedCreativeReview.creative"
        class="mb-2"
      >
        Are you sure you want to approve <strong>{{ selectedCreativeReview.creative.name }}</strong>?
      </p>

      <template #modal-footer>
        <b-button
          type="button"
          variant="secondary"
          size="sm"
          :disabled="isLoadingApproved"
          @click="$bvModal.hide('confirm-approve-modal')"
        >
          Cancel
        </b-button>

        <BaseButtonLoader
          :loading="isLoadingApproved"
          class="ml-2"
          type="button"
          :custom-button="false"
          size="sm"
          variant="primary"
          @click="approveCreative"
        >
          Approve
        </BaseButtonLoader>
      </template>
    </b-modal>

    <b-modal
      id="confirm-reject-modal"
      ref="confirm-reject-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm Creative Rejection"
      ok-title="Reject"
      button-size="sm"
      @ok="rejectCreative"
    >
      <p
        v-if="selectedCreativeReview && selectedCreativeReview.creative"
        class="mb-2"
      >
        Are you sure you want to reject <strong>{{ selectedCreativeReview.creative.name }}</strong>?
      </p>

      <BaseFormTextArea
        v-model="selectedCreativeReview.rejectionReason"
        :horizontal="true"
        :small="true"
        internal-platform-input
        label="Rejection reason"
        width-lg="5"
        width-xl="3"
        type="text"       
        rows="3"
        plaintext
        required
        input-class="px-0"   
      />

      <template #modal-footer>
        <b-button
          type="button"
          variant="secondary"
          size="sm"
          :disabled="isLoadingRejected"
          @click="$bvModal.hide('confirm-reject-modal')"
        >
          Cancel
        </b-button>

        <BaseButtonLoader
          :loading="isLoadingRejected"
          class="ml-2"
          type="button"
          :custom-button="false"
          size="sm"
          variant="danger"
          @click="rejectCreative"
        >
          Reject
        </BaseButtonLoader>
      </template>
    </b-modal>
  </div>
</template>
        
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import VueLightbox from 'vue-lightbox-lite'
import 'vue-lightbox-lite/dist/index.css'

import { showErrorMessage } from '@/notification-utils'
import formInputMixin from '@/mixins/formInputMixin'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
        
export default {
  name: 'ListCreativeReviews',
  
  components: {
    VueLightbox
  },
        
  mixins: [formInputMixin, copyToClipboardMixin],

  props: {
    simpleView: {
      type: Boolean,
      default: false
    },

    creativeReviews: {
      type: Array,
      default() {
        return []
      }
    },

    statusFilter: {
      type: String,
      default: 'Pending'
    },

    filterByCreativeId: {
      type: String,
      default: ''
    },
  },
        
  data() {
    return {
      lightboxVisible: false,
      lightboxIndex: 0,
      currentLightboxIndex: 0,
      loadingFiles: {},
      isLoadingRejected: false,
      isLoadingApproved: false,
      shouldReview: false,
      pageSize: 15,
      currentPage: 1,
      filters: {        
        status: this.statusFilter,
        name:'',
        cmsId: '',
        landingPageUrl:'',
        categoryName:'',
        trafficSourceName: '',
        country:'',
        language:''       
      },

      dropdownFilterOptions: {
        'status': {
          filterOptions: Object.freeze([{
            value: null,
            text: 'Filter by Status'
          },
          {
            value: 'Pending',
            text: 'Review Pending'
          },
          {
            value: 'Rejected',
            text: 'Rejected'
          },
          {
            value: 'Approved',
            text: 'Approved'
          }]),
          customClass: 'fixed-width-140',
          placeholder: 'Status'
        }
      }
    }
  },
  
  computed: {
    ...mapState('creative', [ 'selectedCreativeReview' ]),
    
    ...mapGetters('creative', ['getSelectedCreativeReviewUrls']),

    fields() {
      const columns = [            
        { key: 'status', label: 'Status', sortable: !this.simpleView, thClass: 'text-center', tdClass: 'text-center', },
        { key: 'name', label: 'Name', sortable: !this.simpleView },
        { key: 'cmsId', label: 'SMID (CMS ID)', sortable: !this.simpleView },      
        { key: 'landingPageUrl', label: 'Landing Page URL', sortable: !this.simpleView },
        { key: 'categoryName', label: 'Category', sortable: !this.simpleView },
        { key: 'trafficSourceName', label: 'Traffic Source', sortable: !this.simpleView },       
        { key: 'country', label: 'Country', sortable: !this.simpleView },
        { key: 'language', label: 'Language', sortable: !this.simpleView },
        { key: 'actions', label: 'Actions', sortable: false }
      ]

      if (this.simpleView) {
        columns.shift()
      }

      return columns
    },

    filteredReviews () { 
      const formatted = this.creativeReviews.map(review => { 
        return {
          id: review.id,        
          status: review.status,
          name: review.creative.name,
          cmsId: review.creative.cmsId,
          landingPageUrl: review.creative.landingPageUrl,
          categoryName: review.creative.categoryName,
          trafficSourceName: review.creative.trafficSourceName,
          country: review.creative.countryCode,
          language: review.creative.languageCode,
          creative: review.creative,
          files: review.creative.files,
        }
      })      

      const filtered = formatted.filter(item => {
        return Object.keys(this.filters).every(key => {
          if (this.filters[key] != null) {
            return item[key]?.trim()?.toLowerCase().includes(this.filters[key]?.toLowerCase())
          }

          return true
        })
      })
      
      return filtered
    }
  },
    
  created() {
  },
  
  methods: {
    ...mapActions('creative', [ 
      'getCreativeReview', 
      'approveCreativeReview', 
      'rejectCreativeReview' 
    ]),
    
    ...mapMutations('creative', ['SET_SELECTED_CREATIVE_REVIEW']),

    filterByCreativeIdField(item, filter = '') {
      return item.creative?.id?.toLowerCase().includes(filter.toLowerCase()) 
             || item.creative?.cmsId?.toLowerCase().includes(filter.toLowerCase())
    },
  
    getStatusMap(status) {
      const statusToVariantMap = {
        Pending: {variant:'secondary', text:'Review Pending'},
        Rejected: {variant:'danger', text:'Rejected'},
        Approved: {variant:'success', text:'Approved'},
        Disabled: {variant:'warning', text:'Disabled'}        
      }

      return statusToVariantMap[status] ?? {variant:'dark', text: ''}
    },

    showConfirmApproveCreative() {      
      this.$bvModal.show('confirm-approve-modal')
      this.lightboxVisible = false
    },

    showConfirmRejectCreative() {      
      this.$bvModal.show('confirm-reject-modal')
      this.lightboxVisible = false
    },
  
    async loadCreativeReview(review){
      if (!review.creative.files || !review.creative.files.length) {
        this.SET_SELECTED_CREATIVE_REVIEW(review)
        this.$bvModal.show('review-modal')
        return
      }

      try {
        this.currentLightboxIndex = 0

        this.$set(this.loadingFiles, review.id, true)

        await this.getCreativeReview(review.id)

        this.lightboxVisible = true

        this.shouldReview = true
      } catch (error) {
        showErrorMessage("Failed to open files")
      } finally {
        this.$set(this.loadingFiles, review.id, false)
      }
    },

    async approveCreative(){
      try {
        this.isLoadingApproved = true

        await this.approveCreativeReview(this.selectedCreativeReview.id)

        this.$bvModal.hide('confirm-approve-modal')
        this.$bvModal.hide('review-modal')
      } catch (error) {
        showErrorMessage("Failed to approve creative")
      } finally {
        this.isLoadingApproved = false
      }
    },

    async rejectCreative(){
      try {
        this.isLoadingRejected = true

        await this.rejectCreativeReview({
          id: this.selectedCreativeReview.id, 
          reason: this.selectedCreativeReview.rejectionReason
        })

        this.$bvModal.hide('confirm-reject-modal')
        this.$bvModal.hide('review-modal')
      } catch (error) {
        showErrorMessage("Failed to reject creative")
      } finally {
        this.isLoadingRejected = false
      }
    },

    onImageIndexChange(oldIndex, newIndex) {
      this.currentLightboxIndex = newIndex
    }
  }
}
</script>
  <style lang="scss" scoped>
  </style>
<template>
  <b-alert
    :show="isRingbaRTBBuyerCampaign"
    variant="info"
  >
    <h6 class="mb-0">
      <font-awesome-icon
        :icon="['fas', 'exclamation-triangle']"
        class="mr-2"
      />
      
      For <strong>Ringba RTB</strong> buyer campaign, these settings only control when we ping your Ringba RTB and all bidding logic will be there.
    </h6>
  </b-alert>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AlertProgrammaticTarget',

  computed: {
    ...mapGetters('campaign', [
      'isRingbaRTBBuyerCampaign'
    ])
  },
}
</script>

<style lang="">
  
</style>
<template>
  <BaseFormInput
    v-bind="$attrs"
    :formatter="currencyFormatter"
    :input-class="inputClass"
    v-on="$listeners"
  >
    <template v-slot:helpText>
      <slot name="helpText" />
    </template>
  </BaseFormInput>
</template>

<script>
import { currencyFormatter } from '@/utils/formatCurrency'

export default {
  name: 'BaseCurrencyInput',
  props: {
    inputClass: {
      type: String,
      default: ''
    },
    maxValue: {
      type: Number,
      default: 99999.99
    }
  },
  methods: {
    currencyFormatter(value) {
      if (value === '$') {
        return undefined
      }

      return currencyFormatter(value, this.maxValue)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

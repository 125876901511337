import SellerOfferApi from '@/api/seller-offer.api'
import { mergeArraysOfObjects } from '@/utils/arrayUtils'

const appliedOfferStatuses = {
  Pending: true,
  Approved: true,
  Rejected: true,
  NotEligible: true,
  ManuallyBlocked: true,
  Withdrawn: true,
}

const canApplyToOfferStatuses = {
  Active: true,
  Withdrawn: true,
  ManuallyBlocked: true,
  Rejected: true,
}

function hasAppliedToOffer(offer) {
  return Boolean(appliedOfferStatuses[offer.status])
}

function getOfferStatus(offer) {
  return offer.status || offer.sellerOffer.status
}

const state = {
  sellerAvailableOffers: [],
  sellerAppliedOffers: [],
  sellerOfferDetails: {},
}

/** 
 * @type {import('vuex').GetterTree<typeof state>} 
 */
const getters = {
  accountId: (state, getters, rootState, rootGetters) => {
    return rootGetters['auth/accountDetails']?.id
  },

  allOffersCombined(state) {
    const allOffers = mergeArraysOfObjects(state.sellerAvailableOffers, state.sellerAppliedOffers, 
      (offer) => offer.id, 
      (sellerRegistration) => sellerRegistration.sellerOffer.id,
      (offerA, offerB) => {
        if (!offerA.createdAt || !offerB.createdAt) {
          return true
        }
        
        return new Date(offerA.createdAt) < new Date(offerB.createdAt)
      }, true)

    return allOffers.map(offer => ({
      hasAppliedToOffer: hasAppliedToOffer(offer),
      canApplyToOffer: canApplyToOfferStatuses[offer.status],
      isDisabled: offer.sellerOffer?.status === 'Disabled',
      isRejected: offer.status === 'Rejected',
      ...offer.sellerOffer,
      ...offer,
      status: getOfferStatus(offer),
      minExpectedBidAmount: offer.status === 'Approved' || offer.status === 'Pending' ? offer.minExpectedBidAmount : undefined
    }))
  }
}

/** 
 * @type {import('vuex').ActionTree<typeof state>} 
 */
const actions = {
  async getSellerAvailableOffers({ getters, commit }) {
    let accountId = getters.accountId

    const response = await SellerOfferApi.getAllSellerOffers(accountId)
    commit('SET_AVAILABLE_SELLER_OFFERS', response.data || [])
  },

  async getSellerAppliedOffers({ getters, commit }, ) {
    let accountId = getters.accountId

    const response = await SellerOfferApi.getSellerOfferRegistrations(accountId)
    commit('SET_APPLIED_SELLER_OFFERS', response.data || [])
  },

  async getSellerOfferDetailsById({ getters, commit }, id) {
    let accountId = getters.accountId

    const response = await SellerOfferApi.getSellerOfferDetailsById(accountId, id)
    commit('SET_REQUEST_DETAILS', response.data || [])
  },
  
  async submitParticipationRequest({getters}, payload) {
    let accountId = getters.accountId

    const response = await SellerOfferApi.submitParticipationRequest(accountId, payload)
    return response
  },

  async withdrawParticipationRequest({getters}, sellerOfferRegistrationId) {
    let accountId = getters.accountId

    const response = await SellerOfferApi.withdrawParticipationRequest(accountId, sellerOfferRegistrationId)
    return response
  },

  async updateMinExpectedBidAmount({getters}, { sellerOfferRegistrationId, minExpectedBidAmount }) {
    let accountId = getters.accountId

    const response = await SellerOfferApi.updateMinExpectedBidAmount(accountId, {
      offerRegistrationId: sellerOfferRegistrationId, 
      minExpectedBidAmount: minExpectedBidAmount
    })
    return response
  },
}

const mutations = {
  SET_AVAILABLE_SELLER_OFFERS(state, data) {
    state.sellerAvailableOffers = data
  },

  SET_APPLIED_SELLER_OFFERS(state, data) {
    state.sellerAppliedOffers = data
  },

  SET_SELLER_OFFER_REGISTRATIONS(state, data) {
    state.sellerAvailableOffers = data
  },

  SET_REQUEST_DETAILS(state, data) {
    state.sellerOfferDetails = data
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import CampaignAPI from '@/api/campaign.api.js'
import { currencyFormatter } from '@/utils/formatCurrency'
import cloneDeep from 'lodash.clonedeep'

export const PAUSED_STATUS = 'Paused'
export const ACTIVE_STATUS = 'Active'
export const RTB_ID_REGEX = /^[a-z0-9]{32}$/

export const campaignRoutingTypes = {
  Static: 'Static',
  RingbaRTB: 'RingbaRTB',
  Programmatic: 'Programmatic'
}

export function getDefaultParser() {
  return {
    parserType: 'jpath'
  }
}

export function convertCurrencyStringToNumber(currencyString) {
  if (!currencyString) {
    return currencyString
  }
  
  currencyString = currencyString.toString()
  return Number(currencyString.replace(/[$,]/g, ''))
}

export function getBadgeVariantForCampaignStatus(campaign) {
  if (!campaign) {
    return 'default'
  }

  if (campaign.isPaused) {
    return 'warning'
  }

  switch (campaign.status) {
  case 'InReview':
    return 'info'
  case 'Active':
    return 'success'
  case 'Paused':
    return 'warning'
  case 'Rejected':
    return 'danger'
  default:
    return 'default'
  }
}

export function getStatusText(campaign) {
  if (!campaign) {
    return ''
  }

  if (campaign.isPaused) {
    return 'Paused'
  }

  if (campaign.status === 'InReview') {
    return 'In Review'
  }

  return campaign.status
}


export function getRtbTagsWithDescription() {
  const rtbTags = [{
    key: "trafficsource",
    value: "trafficsource",
    description: "Traffic Source"
  },
  {
    key: "thirdparty",
    value: "thirdparty",
    description: "Third Party"
  },
  {
    key: "state",
    value: "state",
    description: "US state"
  },
  {
    key: "zipcode",
    value: "zipcode",
    description: "ZIP code"
  },
  {
    key: "age",
    value: "age",
    description: "Age"
  },
  {
    key: "gender",
    value: "gender",
    description: "Gender"
  },
  {
    key: "estimatedhouseholdincome",
    value: "estimatedhouseholdincome",
    description: "Estimated Household Income"
  },
  {
    key: "homeowner",
    value: "homeowner",
    description: "Homeowner"
  },
  {
    key: "creative",
    value: "creative",
    description: "Creative"
  },
  {
    key: "channel",
    value: "channel",
    description: "Channel Name"
  }]

  const tagDescriptionMapping = new Map(rtbTags.map(tag => [tag.key, tag.description]))

  return { rtbTags, tagDescriptionMapping }
}

function transformBuyerCampaignForAPI(buyerCampaign) {
  const tempCampaignClone = cloneDeep(buyerCampaign)

  tempCampaignClone.audience = {
    ...tempCampaignClone.audience,
    ageRange: tranformRangeFilterForAPI(tempCampaignClone.audience.ageRange),
    estimatedHouseholdIncomeRangeInDollars: tranformRangeFilterForAPI(tempCampaignClone.audience.estimatedHouseholdIncomeRangeInDollars),
  }

  tempCampaignClone.budget = {
    ...tempCampaignClone.budget,
    dailyBudgetInUSD: Number(tempCampaignClone.budget.dailyBudgetInUSD.replace(/[$,]/g, '')),
    // maxBidPerCallInUSD is required by the backend and must be in range 1.0-9999.99
    // It will default to 1 only if the field is not visible in UI and validation does not force user to enter the value, like for RTB campaigns
    maxBidPerCallInUSD: Number(tempCampaignClone.budget.maxBidPerCallInUSD?.replace(/[$,]/g, '') ?? 1)
  }

  if (tempCampaignClone.routing.destinationType === 'SIP') {
    tempCampaignClone.routing.number = ''
  } else {
    tempCampaignClone.routing.sipNumber = {}
  }

  if (tempCampaignClone.routing.type === 'Programmatic') {
    const tempProgrammaticTarget = {
      ...tempCampaignClone.routing.programmaticTarget
    }

    const transformedHeaders = tranformHeaders(tempProgrammaticTarget.headers)

    if (!tempProgrammaticTarget.bidSettings.isBidDynamic) {
      delete tempProgrammaticTarget.dynamicBidAmountParser
    }

    if (!tempProgrammaticTarget.bidSettings.conversionCallLengthDynamic) {
      delete tempProgrammaticTarget.dynamicCallLengthSettingParser
    }

    if (!tempProgrammaticTarget.isNumberDynamic) {
      delete tempProgrammaticTarget.dynamicDialSettingParser
    }

    tempProgrammaticTarget.bidSettings = {
      ...tempProgrammaticTarget.bidSettings,
      staticBidAmount: convertCurrencyStringToNumber(tempProgrammaticTarget.bidSettings.staticBidAmount),
      bidOnFailure: convertCurrencyStringToNumber(tempProgrammaticTarget.bidSettings.bidOnFailure)
    }

    tempProgrammaticTarget.headers = transformedHeaders

    tempCampaignClone.routing = {
      ...tempCampaignClone.routing,
      programmaticTarget: tempProgrammaticTarget
    }
  } else {
    const tempRingbaRTBTarget = {
      ...tempCampaignClone.routing.ringbaRTB
    }

    let transformedTagMappings = null

    if (tempRingbaRTBTarget.tagMappings?.length) {
      transformedTagMappings = tranformHeaders(tempRingbaRTBTarget.tagMappings)
    }

    tempRingbaRTBTarget.tagMappings = transformedTagMappings

    tempCampaignClone.routing = {
      ...tempCampaignClone.routing,
      programmaticTarget: null,
      ringbaRTB: tempRingbaRTBTarget
    }
  }

  if (tempCampaignClone.routing.type !== 'RingbaRTB') {
    tempCampaignClone.routing = {
      ...tempCampaignClone.routing,
      ringbaRTB: null
    }
  }

  tempCampaignClone.trafficSources = {
    ...tempCampaignClone.trafficSources,
    allowedSources: tempCampaignClone.trafficSources.allowedSources.map(id => {
      return {
        id
      }
    })
  }

  return tempCampaignClone
}

function tranformHeaders(headers) {
  let tempHeaders = {}

  headers?.forEach(header => {
    tempHeaders[header.key] = header.value
  })

  return tempHeaders
}


function transformHeadersFromAPIToUI(headers) {
  return  headers
    ? Object.entries(headers).map(entry => ({
      key: entry[0],
      value: entry[1]
    }))
    : []

}

function tranformRangeFilterForAPI(range) {
  if (range === null){
    return null
  } 

  return {
    min: Number(range.min.replace(/[$,]/g, '')),
    max: Number(range.max.replace(/[$+,]/g, '')),
    greaterThanEqualToMax: range.max.includes('+')
  }
}

function getDefaultProgrammaticTarget() {
  return {
    bidSettings: {
      staticBidAmount: '$1',
      bidOnFailure: '$0.5',
      bidType: 'Static',
      isBidDynamic: false,
      conversionCallLengthDynamic: false,
      conversionCallLengthInSeconds: 1,
      maxConversionCallDurationSeconds: 1,
    },
    destinationType: 'Number',
    number: '',
    sipNumber: {},
    method: null,
    isNumberDynamic: false,
    headers: [],

    dynamicBidAmountParser: [getDefaultParser()],
    shouldAcceptCallParser: [getDefaultParser()],
    dynamicDialSettingParser: [getDefaultParser()],
    dynamicCallLengthSettingParser: [getDefaultParser()],
  }
}

function getDefaultBuyerCampaign() {
  return {
    categoryId: null,
    countryId: null,
    languageId: null,
    name: '',
    routing: {
      type: 'Static',
      destinationType: 'Number',
      number: '+1',
      sipNumber: {},
      programmaticTarget: getDefaultProgrammaticTarget(),
      ringbaRTB: {},

      schedule: {
        hoursOfOperation: null,
        timeZoneId: null,
        concurrencyCap: null,
        hourlyConcurrencyCap: null
      },
    },
    trafficSources: {
      callType: 'Inbound',
      requirements: 'None',
      allowThirdParties: true,
      allowTollFreeCallers: true,
      allowDuplicateCallers: true,
      allowedSources: []
    },
    audience: {
      lineTypeSelected: false,
      searchLocation: false,
      selectedLocations: [],
      locationFilterType: 'States',
      noIcp: true,
      lineTypes: [],
      wirelessCarrierSelected: false,
      wirelessCarriers: [],
      expandAudience: true,
      phoneType: '',
      gender: null,
      ageRange: null,
      estimatedHouseholdIncomeRangeInDollars: null,
    },

    // integrations: {},

    budget: {
      minimumDurationSelected: false,
      minimumDurationInSeconds: 30,
      //Do not "suggest" any monetary values here
      // dailyBudgetInUSD: '$10',
      // maxBidPerCallInUSD: '$1'
    }
  }
}

function transformBuyerCampaignFromAPIForUI(campaign) {
  let transformedCampaign = {...campaign}

  if (!transformedCampaign.routing.programmaticTarget) {
    transformedCampaign.routing.programmaticTarget = getDefaultProgrammaticTarget()
  }

  if (transformedCampaign.routing.type === 'Programmatic') {
    transformedCampaign.routing.programmaticTarget.headers = transformHeadersFromAPIToUI(transformedCampaign.routing.programmaticTarget.headers)
  }

  if (transformedCampaign.routing.type === 'RingbaRTB') {
    transformedCampaign.routing.ringbaRTB.tagMappings = transformHeadersFromAPIToUI(transformedCampaign.routing.ringbaRTB.tagMappings)
  }

  const programmaticTarget = transformedCampaign.routing.programmaticTarget

  if (!programmaticTarget.dynamicBidAmountParser) {
    programmaticTarget.dynamicBidAmountParser = [getDefaultParser()]
  }

  if (!programmaticTarget.shouldAcceptCallParser) {
    programmaticTarget.shouldAcceptCallParser = [getDefaultParser()]
  }

  if (!programmaticTarget.dynamicDialSettingParser) {
    programmaticTarget.dynamicDialSettingParser = [getDefaultParser()]
  }

  if (!programmaticTarget.dynamicCallLengthSettingParser) {
    programmaticTarget.dynamicCallLengthSettingParser = [getDefaultParser()]
  }

  programmaticTarget.bidSettings.bidType = programmaticTarget.bidSettings.isBidDynamic ? 'Dynamic' : 'Static'

  transformedCampaign.routing.ringbaRTB =  {
    ...campaign.routing.ringbaRTB
  }

  transformedCampaign.categoryId = campaign.category.id
  transformedCampaign.countryId = campaign.country.id
  transformedCampaign.languageId = campaign.language.id

  transformedCampaign.trafficSources.allowedSources = transformedCampaign.trafficSources.allowedSources.map(ts => ts.id)

  if (transformedCampaign.audience.ageRange !== null) {
    transformedCampaign.audience.ageRange = {
      min: transformedCampaign.audience.ageRange.min.toString(),
      max: `${transformedCampaign.audience.ageRange.max}${transformedCampaign.audience.ageRange.greaterThanEqualToMax ? '+' : ''}`,
    }
  }

  if (transformedCampaign.audience.estimatedHouseholdIncomeRangeInDollars !== null) {
    transformedCampaign.audience.estimatedHouseholdIncomeRangeInDollars = {
      min: `$${transformedCampaign.audience.estimatedHouseholdIncomeRangeInDollars.min.toLocaleString('en-US')}`,
      max: `$${transformedCampaign.audience.estimatedHouseholdIncomeRangeInDollars.max.toLocaleString('en-US')}${transformedCampaign.audience.estimatedHouseholdIncomeRangeInDollars.greaterThanEqualToMax ? '+' : ''}`,
    }
  }  

  transformedCampaign.audience.lineTypeSelected = !!campaign.audience?.lineTypes?.length
  transformedCampaign.audience.wirelessCarrierSelected = !!campaign.audience?.wirelessCarriers?.length

  transformedCampaign.budget.dailyBudgetInUSD = currencyFormatter(transformedCampaign.budget.dailyBudgetInUSD)
  transformedCampaign.budget.maxBidPerCallInUSD = currencyFormatter(transformedCampaign.budget.maxBidPerCallInUSD)

  transformedCampaign.audience.searchLocation = campaign.audience.searchLocation
  transformedCampaign.audience.selectedLocations = campaign.audience.selectedLocations
  
  return transformedCampaign
}

const state = {
  buyerCampaigns: [],
  buyerCampaign: getDefaultBuyerCampaign()
}

const getters = {
  isProgrammaticBuyerCampaign(state) {
    return state.buyerCampaign?.routing?.type === campaignRoutingTypes.Programmatic
  },

  isRingbaRTBBuyerCampaign(state) {
    return state.buyerCampaign?.routing?.type === campaignRoutingTypes.RingbaRTB
  }
}

const actions = {
  async createBuyerCampaign({ rootGetters }, campaign) {
    const response = await CampaignAPI.createBuyerCampaign({
      accountId: rootGetters['auth/accountDetails']?.id,
      campaign: transformBuyerCampaignForAPI(campaign)
    })

    return response.data
  },

  async fetchBuyerCampaigns({ commit, rootGetters }) {
    const response = await CampaignAPI.listBuyerCampaigns(
      rootGetters['auth/accountDetails']?.id,
    )

    commit('SET_BUYER_CAMPAIGNS', response.data)
  },

  async fetchBuyerCampaign({ commit, rootGetters, dispatch }, id) {
    const response = await CampaignAPI.getBuyerCampaign(
      rootGetters['auth/accountDetails']?.id,
      id
    )

    commit('SET_BUYER_CAMPAIGN', transformBuyerCampaignFromAPIForUI(response.data))

    if (response.data.isDailyBudgetReached) {
      dispatch('showDashboardNotification', {
        text: 'Your campaign daily budget is exceeded. ',      
        hideOnClick: false
      }, { root:true })
    }
  },

  async pauseBuyerCampaign({ commit, rootGetters }, { campaignId }) {
    const response = await CampaignAPI.pauseBuyerCampaign({
      accountId: rootGetters['auth/accountDetails']?.id,
      campaignId
    })

    commit('UPDATE_BUYER_CAMPAIGN_STATUS_IN_LIST', { campaignId, isPaused: true })

    return response.data
  },

  async resumeBuyerCampaign({ commit, rootGetters }, { campaignId, status }) {
    const response = await CampaignAPI.resumeBuyerCampaign({
      accountId: rootGetters['auth/accountDetails']?.id,
      campaignId
    })

    commit('UPDATE_BUYER_CAMPAIGN_STATUS_IN_LIST', { 
      campaignId,
      isPaused: false,
      status: status === PAUSED_STATUS ? ACTIVE_STATUS : status
    })

    return response.data
  },

  async pauseSellerCampaign(context, { campaignId }) {
    const response = await CampaignAPI.pauseSellerCampaign({
      campaignId
    })
    return response.data
  },

  async resumeSellerCampaign(context, { campaignId }) {
    const response = await CampaignAPI.resumeSellerCampaign({
      campaignId
    })
    return response.data
  },

  async updateCampaignBid(context, { campaignId, bidValue }) {
    const response = await CampaignAPI.updateCampaignBid({
      campaignId,
      bidValue
    })
    return response.data
  },

  async editBuyerCampaign({ rootGetters }, campaign) {
    campaign.country = campaign.countryId
    campaign.category = campaign.categoryId
    campaign.language = campaign.languageId    

    if (!campaign.audience.wirelessCarrierSelected) {
      campaign.audience.wirelessCarriers = []
    }
    if (!campaign.audience.lineTypeSelected) {
      campaign.audience.lineTypes = []
    }

    const response = await CampaignAPI.editBuyerCampaign({
      accountId: rootGetters['auth/accountDetails']?.id,
      campaign: transformBuyerCampaignForAPI(campaign)
    })

    return response.data
  },


  updateDynamicTargetInBuyerCampaign({ commit }, target) {
    commit('UPDATE_BUYER_CAMPAIGN_DYNAMIC_TARGET', target)
  },

  setDefaultBuyerCampaign({ commit }) {
    commit('SET_DEFAULT_BUYER_CAMPAIGN')
  }
}

const mutations = {
  SET_DEFAULT_BUYER_CAMPAIGN(state) {
    state.buyerCampaign = getDefaultBuyerCampaign()
  },

  SET_BUYER_CAMPAIGN(state, campaign) {
    state.buyerCampaign = campaign
  },

  SET_BUYER_CAMPAIGNS(state, campaigns) {
    state.buyerCampaigns = campaigns
  },

  UPDATE_BUYER_CAMPAIGN_STATUS_IN_LIST(state, { campaignId, status, isPaused }) {
    const buyerCampaignIndex = state.buyerCampaigns.findIndex(c => c.id === campaignId)

    if (status) {
      state.buyerCampaigns[buyerCampaignIndex].status = status
    }
    
    state.buyerCampaigns[buyerCampaignIndex].isPaused = isPaused

    state.buyerCampaigns = [...state.buyerCampaigns]
  },

  UPDATE_BUYER_CAMPAIGN_BASIC_DETAILS(state, { name, categoryId, countryId, languageId }) {
    state.buyerCampaign = {
      ...state.buyerCampaign,
      name,
      categoryId,
      countryId,
      languageId
    }
  },
  UPDATE_BUYER_CAMPAIGN_ROUTING(state, routing) {
    state.buyerCampaign.routing = routing
  },
  UPDATE_BUYER_CAMPAIGN_DYNAMIC_TARGET(state, programmaticTarget) {
    state.buyerCampaign.routing.programmaticTarget = programmaticTarget
  },
  UPDATE_BUYER_CAMPAIGN_TRAFFIC_SOURCES(state, trafficSources) {
    state.buyerCampaign.trafficSources = trafficSources
  },
  UPDATE_BUYER_CAMPAIGN_AUDIENCE(state, audience) {
    state.buyerCampaign.audience = audience
  },
  UPDATE_BUYER_CAMPAIGN_INTEGRATIONS(state, integrations) {
    state.buyerCampaign.integrations = integrations
  },
  UPDATE_BUYER_CAMPAIGN_BUDGET(state, budget) {
    state.buyerCampaign.budget = budget
  },

  RESET_BUYER_CAMPAIGN_MINIMUM_DURATION(state) {
    state.buyerCampaign.budget = {
      ...state.buyerCampaign.budget,
      minimumDurationInSeconds: 1,
      minimumDurationSelected: false
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class="d-flex flex-column mr-3">
    <Panel
      size="small"
      loader-text="Loading Offers"
      :loading="loadingOffers"
      :show-loading-error="showLoadingError"
      :loading-error-text="loadingErrorText"
    >
      <template v-slot:header>
        <span data-cy="heading">Offers</span>
      </template>
      <template v-slot:content>
        <b-table
          :fields="fields"
          data-cy="table-seller-offers"
          :items="allOffersCombined"
          thead-class="text-nowrap"
          tbody-tr-class="text-nowrap"
          primary-key="id"
        >
          <template #cell(status)="row">
            <b-badge
              tag="div"
              class="w-100 py-1"
              :variant="getBadgeVariant(row.item)"
            >
              {{ row.item.isDisabled ? 'Disabled' : (statusNames[row.item.status] || row.item.status) }}
            </b-badge>
          </template>

          <template #cell(createdAt)="row">
            {{ row.item.hasAppliedToOffer ? dateFormat(row.item.createdAt, 'MMM DD, YYYY') : '' }}
          </template>

          <template #cell(category.name)="row">
            <div class="text-nowrap">
              {{ row.item.category.name }}
            </div>
          </template>

          <template #cell(language.name)="row">
            <div class="text-nowrap">
              {{ row.item.language.name }}
            </div>
          </template>

          <template #cell(qualityScore)="row">
            <div class="text-nowrap">
              {{
                dashIfNotAvailable(
                  () => `${row.item.qualityScore.toFixed(2)} / 10`
                )
              }}
            </div>
          </template>

          <template #cell(liveConcurrency)="row">
            <div class="text-nowrap">
              {{
                renderFractionInUSLocale(
                  row.item.liveConcurrency,
                  row.item.liveConcurrencyCap
                )
              }}
            </div>
          </template>

          <template #cell(dailyConcurrency)="row">
            <div class="text-nowrap">
              {{
                renderFractionInUSLocale(
                  row.item.dailyConcurrency,
                  row.item.dailyConcurrencyCap
                )
              }}
            </div>
          </template>

          <template #cell(weeklyConcurrency)="row">
            <div class="text-nowrap">
              {{
                renderFractionInUSLocale(
                  row.item.weeklyConcurrency,
                  row.item.weeklyConcurrencyCap
                )
              }}
            </div>
          </template>

          <template #cell(monthlyConcurrency)="row">
            <div class="text-nowrap">
              {{
                renderFractionInUSLocale(
                  row.item.monthlyConcurrency,
                  row.item.monthlyConcurrencyCap
                )
              }}
            </div>
          </template>

          <template #cell(rateLimit)="row">
            <div class="text-nowrap">
              {{ row.item.rateLimit ? `${renderNumberInUSLocale(row.item.rateLimit)} PPM ` : '-' }}
            </div>
          </template>

          <template #cell(bidTTLInSeconds)="row">
            <div class="text-nowrap">
              {{
                row.item.bidTTLInSeconds
                  ? `${renderNumberInUSLocale(row.item.bidTTLInSeconds)} seconds `
                  : '-'
              }}
            </div>
          </template>

          <template #cell(minExpectedBidAmount)="row">
            <div class="text-nowrap">
              {{
                row.item.minExpectedBidAmount != null
                  ? `${currencyFormatter(row.item.minExpectedBidAmount)}`
                  : ''
              }}
            </div>
          </template>

          <template #cell(actions)="row">
            <SellerAppliedOfferActions
              v-if="!row.item.canApplyToOffer || row.item.isRejected"
              :offer-registration="row.item"
              :class="{'mr-2': row.item.isRejected}"
              @showApplicationRequestDetails="openApplicationRequestDetailsModal"
              @showBiddingUrl="getBiddingURLForOfferRegistration"
              @showWithdrawModal="openWithdrawApplicationModal"
              @showRejectionModal="openRejectionReasonModal"
              @showAdjustExpectedBidAmountModal="openUpdateMinExpectedBidModal"
            />

            <b-button 
              v-if="row.item.canApplyToOffer"
              variant="outline-info"
              size="sm"
              @click="openApplyToRunModal(row.item)"
            >
              Apply to Run
              <font-awesome-icon
                v-if="!loading"
                class="ml-2"
                data-cy="icon-pause"
                :icon="['fas', 'play']"
                :style="{ cursor: 'pointer' }"
              />
            </b-button>
          </template>
        </b-table>
      </template>
    </Panel>

    <SellerApplyToRunModal
      v-if="selectedOffer"
      :offer="selectedOffer"
      @hidden="handleHideModal"
      @success="getSellerAppliedOffers"
      @shown="handleModalShown"
    />

    <RejectionReasonModal
      v-if="selectedRegistration"
      :registration="selectedRegistration"
      @hidden="handleHideModal"
    />

    <SellerApplicationDetailsModal
      v-if="selectedRegistration"
      :registration="selectedRegistration"
      @hidden="handleHideModal"
      @shown="handleModalShown"
    />

    <b-modal
      v-if="selectedRegistration"
      id="confirm-withdraw-modal"
      ref="confirm-withdraw-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      :title="`Confirm withdrawal`"
      ok-variant="danger"
      ok-title="Withdraw"
      button-size="sm"
      @ok="withdrawSellerApplication"
      @hidden="handleHideModal"
    >
      <p class="mb-0">
        Are you sure you want to withdraw your application to the <strong>{{ selectedRegistrationOfferName }}</strong>?
      </p>
      <p class="mb-0">
        Withrawing your application will stop the calls traffic to the marketplace.
      </p>
    </b-modal>

    <b-modal
      id="bidding-url-modal"
      ref="bidding-url-modal"
      v-model="showBiddingUrl"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Bidding URL"
      ok-variant="primary"
      ok-title="Ok"
      button-size="sm"
      size="lg"
      auto-focus-button="ok"
      ok-only
      @ok="showBiddingUrlModal(false)"
      @hidden="handleHideModal"
    >
      <Loader v-if="loadingBiddingUrl" />
      <SellerOfferBiddingDetails
        v-else-if="sellerOfferBiddingDetails"
        :bidding-details="sellerOfferBiddingDetails"
      />
    </b-modal>

    <b-modal
      id="update-expected-bid-modal"
      ref="update-expected-bid-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Update Minimum Expected Bid"
      size="lg"
      hide-footer
      @hidden="handleHideModal"
    >
      <h6 class="alert alert-light mb-4">
        <font-awesome-icon
          :icon="['fas', 'info-circle']"
          class="mr-2"
        />
        This helps us provide guidance to buyers to get close or exceed what you’re looking for
      </h6>

      <validation-observer
        v-if="selectedRegistration"
        ref="formValidation"
        v-slot="{ handleSubmit }"
      >
        <b-form
          novalidate
          @submit.stop.prevent="handleSubmit(updateMinExpectedBid)"
        >
          <BaseCurrencyInput
            v-model="selectedRegistrationMinExpectedBidAmount"
            internal-platform-input
            horizontal
            small
            name="Minimum Expected Bid"
            placeholder="eg. $100.00"
            rules="amount_range:1,9999.99"
            label="Minimum Expected Bid"
            required
            width="2"
            width-lg="4"
            width-xs="6"
            input-class="fixed-width-130"
            :max-value="9999.99"
            min="0"
          />

          <b-row>
            <b-col
              offset-lg="3"
            >
              <ButtonLoader
                variant="primary"
                :custom-button="false"
                :loading="isLoadingUpdateMinExpectedBid"
                type="submit"
              >
                Update
              </ButtonLoader>

              <b-button
                type="button"
                variant="danger"
                class="ml-3"         
                @click="$bvModal.hide('update-expected-bid-modal')"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import fractionMixin from '@/mixins/numbersInLocaleMixin'
import dateFormatMixin from '@/mixins/dateFormatMixin'

import SellerApplyToRunModal from './SellerApplyToRunModal.vue'
import SellerAppliedOfferActions from './SellerAppliedOfferActions.vue'
import RejectionReasonModal from './RejectionReasonModal.vue'
import SellerApplicationDetailsModal from './SellerApplicationDetailsModal.vue'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import SellerOfferApi from '@/api/seller-offer.api'
import SellerOfferBiddingDetails from './SellerOfferBiddingDetails.vue'
import { currencyFormatter } from '@/utils/formatCurrency'
import { convertCurrencyStringToNumber } from '@/store/modules/campaign.store'

const statusNames = {
  Active: 'Available',
  ManuallyBlocked: 'Removed'
}

export default {
  name: 'SellerAvailableOffers',

  title: 'Available Offers',

  components: {
    SellerApplyToRunModal,
    SellerAppliedOfferActions,
    RejectionReasonModal,
    SellerApplicationDetailsModal,
    SellerOfferBiddingDetails,
  },

  mixins: [fractionMixin, dateFormatMixin],

  props: {
    sellerOfferId: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      loadingOffers: false,

      showLoadingError: false,

      loading: false,

      loadingBiddingUrl: false,

      isLoadingUpdateMinExpectedBid: false,
      
      selectedOffer: null,
      
      selectedRegistration: null,

      showBiddingUrl: false,

      sellerOfferBiddingDetails: null,

      statusNames: Object.freeze(statusNames),

      loadingErrorText: '',

      fields: [
        {
          key: 'status',
          label: 'Status',
          tdAttr: value => {
            return {
              'data-cy': 'status-badge',
              'data-cy-status': value
            }
          },
          sortable: true
        },
        { key: 'createdAt', label: 'Applied', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'category.name', label: 'Category', sortable: true },
        { key: 'language.name', label: 'Language', sortable: true },
        { key: 'allowedTrafficSourceType', label: 'Type', sortable: true },
        { key: 'minExpectedBidAmount', label: 'Min. Expected Bid', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
        // { key: 'qualityScore', label: 'Quality' },
        // { key: 'liveConcurrency', label: 'Concurrency' },
        // { key: 'dailyConcurrency', label: 'Daily' },
        // { key: 'weeklyConcurrency', label: 'Weekly' },
        // { key: 'monthlyConcurrency', label: 'Monthly' },
        // { key: 'rateLimit', label: 'Rate Limit' },
        // { key: 'bidTTLInSeconds', label: 'Bid TTL' },
        { key: 'actions', label: 'Actions' }
      ],
    }
  },

  computed: {
    ...mapGetters('auth', ['accountId']),

    ...mapGetters('sellerOffer', [
      'allOffersCombined'
    ]),

    selectedRegistrationOfferName() {
      return this.selectedRegistration?.selectedOffer?.name
    },

    selectedRegistrationMinExpectedBidAmount: {
      get() {
        return this.selectedRegistration?.minExpectedBidAmount != null ? currencyFormatter(this.selectedRegistration?.minExpectedBidAmount) : ''
      },

      set(value) {
        this.selectedRegistration = {
          ...this.selectedRegistration,
          minExpectedBidAmount: convertCurrencyStringToNumber(value)
        }
      }
    }
  },

  watch: {
    sellerOfferId(newValue) {
      this.handleSellerOfferFromRoute(newValue)
    }
  },

  async created() {
    try {
      this.loadingOffers = true
      await Promise.all([await this.getSellerAvailableOffers(), await this.getSellerAppliedOffers()])

      this.handleSellerOfferFromRoute(this.sellerOfferId)
    } catch (error) {
      this.loadingErrorText = error?.response?.data?.title
    } finally {
      this.loadingOffers = false
    }
  },

  methods: {
    ...mapActions('sellerOffer', [
      'getSellerAvailableOffers',
      'getSellerAppliedOffers',
      'withdrawParticipationRequest',
      'updateMinExpectedBidAmount'
    ]),

    currencyFormatter,

    handleSellerOfferFromRoute(sellerOfferId) {
      if (!this.sellerOfferId) {
        this.$bvModal.hide('apply-to-run-modal')
        this.$bvModal.hide('application-details-modal')
        return
      }
      
      const selectedOffer = this.allOffersCombined.find(so => so.id === sellerOfferId || so.sellerOffer?.id === sellerOfferId)

      if (!selectedOffer) {
        this.rerouteWithQueryParam()
        return
      }

      if (selectedOffer.canApplyToOffer) {
        this.openApplyToRunModal(selectedOffer)
      } else if (!selectedOffer.canApplyToOffer) {
        this.openApplicationRequestDetailsModal(selectedOffer)
      }
    },

    rerouteWithQueryParam(sellerOfferId) {
      if (!sellerOfferId) {
        this.$router.push({ name: 'SellerAvailableOffers', query: {} })
      } else {
        this.$router.push({ name: 'SellerAvailableOffers', query: {sellerOfferId} })
      }
    },

    getBadgeVariant(offer) {
      const status = offer.isDisabled ? 'Disabled' : offer.status

      const statusToVariantMap = {
        Active: 'primary',
        Disabled: 'light',
        Pending: 'secondary',
        Approved: 'success',
        Rejected: 'danger',
        NotEligible: 'danger',
        Withdrawn: 'warning',
        ManuallyBlocked: 'info'
      }

      return statusToVariantMap[status]
    },

    openApplyToRunModal(offer) {
      if (offer.hasAppliedToOffer && offer.canApplyToOffer) {
        offer = offer.sellerOffer
      }

      this.loading = true
      this.selectedOffer = { ...offer }

      setTimeout(() => {
        this.$bvModal.show('apply-to-run-modal')
      })

      this.loading = false
    },

    openApplicationRequestDetailsModal(registration) {
      this.selectedRegistration = { ...registration }

      setTimeout(() => {
        this.$bvModal.show('application-details-modal')
      })
    },

    openWithdrawApplicationModal(registration) {
      this.selectedRegistration = { ...registration }

      setTimeout(() => {
        this.$bvModal.show('confirm-withdraw-modal')
      })
    },

    openUpdateMinExpectedBidModal(registration) {
      this.selectedRegistration = { ...registration }

      setTimeout(() => {
        this.$bvModal.show('update-expected-bid-modal')
      })
    },

    openRejectionReasonModal(registration) {
      this.selectedRegistration = { ...registration }

      setTimeout(() => {
        this.$bvModal.show('rejection-reason-modal')
      })
    },

    async withdrawSellerApplication() {
      try {
        this.loading = true

        await this.withdrawParticipationRequest(this.selectedRegistration.id)
        showSuccessMessage('Successfully withdrawn your application request')
        
        await this.getSellerAppliedOffers()
      } catch (error) {
        showErrorMessage(`${error.response?.data?.title} ${JSON.stringify(error.response?.data?.errors)}`)
      } finally {

        this.loading = false
      }
    },

    async updateMinExpectedBid() {
      try {
        this.isLoadingUpdateMinExpectedBid = true

        await this.updateMinExpectedBidAmount({ 
          sellerOfferRegistrationId: this.selectedRegistration.id, 
          minExpectedBidAmount: this.selectedRegistration.minExpectedBidAmount 
        })

        showSuccessMessage('Successfully updated minimum expected bid')

        this.$bvModal.hide('update-expected-bid-modal')
        
        await this.getSellerAppliedOffers()
      } catch (error) {
        showErrorMessage(`${error.response?.data?.title} ${JSON.stringify(error.response?.data?.errors)}`)
      } finally {
        this.isLoadingUpdateMinExpectedBid = false
      }
    },

    async getBiddingURLForOfferRegistration(offerRegistration) {
      try {
        this.loadingBiddingUrl = true
        this.showBiddingUrl = true

        const response = await SellerOfferApi.getSellerOfferBiddingURL(this.accountId, offerRegistration.id)

        this.sellerOfferBiddingDetails = response.data
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
      } finally {
        this.loadingBiddingUrl = false
      }
    },

    showBiddingUrlModal(show) {
      this.showBiddingUrl = show
    },

    handleHideModal() {
      this.selectedRegistration = null
      this.selectedOffer = null
      this.rerouteWithQueryParam()
    },

    handleModalShown() {
      if (this.sellerOfferId) {
        return
      }

      this.rerouteWithQueryParam(this.selectedOffer?.id || this.selectedRegistration?.sellerOffer?.id)
    }
  }
}
</script>

<style lang="scss" scoped></style>
